import { graphql } from "gatsby"
import React, { useEffect } from "react"
import LayoutComponent from "../LayoutComponent/LayoutComponent"
import MenuComponent from "../MenuComponent/MenuComponent"
import "./NewsComponentStyle.scss"
import EmptyImage from "../../assets/image/bcrd_placeholder.png"
import { unloadRecaptcha } from "../../utils/Captcha"

const NewsTemplate = ({ data }) => {
  const newsData = data.allSitePage.edges[0].node.context
  const isEnglish: boolean = data.allSitePage.edges[0].node.path.includes(
    "/en/news/"
  )

  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent isEnglish={isEnglish}>
      <MenuComponent selected={0} isEnglish={isEnglish} />
      <h2 className="underline-headline a-little-bit-of-margin-top">
        {newsData.title}
      </h2>
      {newsData.image === "" ? (
        <img src={EmptyImage} className="news-detail-image" />
      ) : (
        <img
          src={"/news/images/" + newsData.image}
          className="news-detail-image"
        />
      )}
      <p className="">{isEnglish ? newsData.subtitleEN : newsData.subtitle}</p>
      <p
        className=""
        dangerouslySetInnerHTML={{
          __html: isEnglish ? newsData.contentTextEN : newsData.contentText,
        }}
      />
    </LayoutComponent>
  )
}

export default NewsTemplate

export const query = graphql`
  query($path: String) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            slug
            image
            title
            subtitle
            subtitleEN
            contentText
            contentTextEN
          }
          path
        }
      }
    }
  }
`
